import React, { useMemo, Fragment } from 'react';
import {
  Form,
  Button,
  Popover,
  Icon,
} from 'antd';
import { FormProps } from 'antd/lib/form';
import TemplateEditor from 'components/TemplateEditor';
import DisplayCard from 'components/DisplayCard';
import cs from 'utils/classNames';
import { ServiceMode } from 'config/data';
import { OrderReminder, ScriptConfigs } from 'apis/orderApi';
import { formatStr } from 'utils';
import { ScriptLabel } from '.';
import styles from './styles.module.scss';

const {
  Item: FormItem,
} = Form;

interface SettingDisplayProps extends FormProps {
  config: OrderReminder,
  updateEditing: (editing: boolean) => void,
}

const SettingDisplay = (props: SettingDisplayProps) => {
  const {
    config,
    updateEditing,
    className,
    ...otherProps
  } = props;

  const currentConfig = config[config.marketingMode];

  const {
    assistantEndTime,
    assistantStartTime,
  } = currentConfig;

  const delayMinutes = useMemo(() => {
    if (currentConfig.delaySeconds) {
      return Math.round(currentConfig.delaySeconds / 60);
    }
    return undefined;
  }, [currentConfig.delaySeconds]);

  return (
    <DisplayCard
      footer={(
        <Button
          type="link"
          onClick={() => updateEditing(true)}
        >
          <Icon type="edit" />
        编辑设置
        </Button>
      )}
    >
      <div className="form">
        <Form
          className={cs([styles['display-form'], className])}
          {...otherProps}
        >
          <FormItem
            label="场景状态"
          >
            <span>{config.enable ? '已开启' : '已关闭'}</span>
          </FormItem>
          {
          config.enable && (
            <>
              <FormItem
                label="下发模式"
              >
                <span>{ServiceMode[config.marketingMode]}</span>
              </FormItem>
              <FormItem
                label="首次触发时间"
              >
                <span>{`发咨询结束 ${delayMinutes || ''} 分钟未下单时`}</span>
              </FormItem>
              <FormItem
                label="单次购物最多催促"
              >
                <span>{`${currentConfig.times}次`}</span>
              </FormItem>
              <FormItem
                label="客服工作时间段"
              >
                <span>
                  {`${assistantStartTime} ~ ${assistantEndTime}`}
                </span>
              </FormItem>
              <FormItem
                label="不下发买家"
              >
                <span
                  style={{ marginLeft: '10px' }}
                >
                  {
                    formatStr({
                      接待中买家: currentConfig.receivingBuyersEnable,
                      [`质保期买家(买家确认收货${currentConfig.coolDownValue}天内，视作质保期买家)`]: currentConfig.coolDownEnable,
                    // eslint-disable-next-line no-unused-vars
                    })
                  }
                </span>
                <span>
                  {
                  !currentConfig.receivingBuyersEnable && !currentConfig.coolDownEnable
                  && '无'
                }
                </span>
              </FormItem>
              <FormItem
                label="营销方式"
              >
                <span>
                  智能化催下单（
                  {
                    Object.entries(config.scriptConfigs as ScriptConfigs)
                      .filter(([, value]) => value.enable)
                      .map(([key, value], index, arr) => {
                        const {
                          templates: [{
                            segments = [],
                          } = {}] = [],
                        } = value;

                        return (
                          <Fragment key={key}>
                            <Popover
                              placement="bottom"
                              content={(
                                <TemplateEditor
                                  className={styles['popover-content']}
                                  value={segments}
                                  readOnly
                                />
                              )}
                            >
                              <span className={styles.example}>{ScriptLabel[key]}</span>
                            </Popover>
                            {
                              (index !== arr.length - 1) && <span>、</span>
                            }
                          </Fragment>
                        );
                      })
                  }
                  ）
                </span>
              </FormItem>
            </>
          )
        }
        </Form>
      </div>
    </DisplayCard>
  );
};

export default SettingDisplay;
