import React, { useState, useEffect, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Icon } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import { Route } from './routes';
import styles from './styles.module.scss';

const { Item: MenuItem } = Menu;

const getIcon = (icon: string | ReactNode) => {
  if (!icon) {
    return null;
  }

  return typeof icon === 'string'
    ? <Icon type="icon" />
    : icon;
};

interface NavProps extends MenuProps {
  routes: Array<Route>,
}

const Nav = (props: NavProps) => {
  const {
    routes,
    ...otherProps
  } = props;

  const { pathname } = useLocation();

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    const activeRoute = routes.find(({ path }) => path === pathname);
    if (activeRoute) {
      setSelectedKeys([activeRoute.path]);
    } else {
      setSelectedKeys([]);
    }
  }, [pathname, routes]);

  return (
    <Menu
      mode="inline"
      multiple={false}
      selectedKeys={selectedKeys}
      {...otherProps}
    >
      {
        routes.map((route) => {
          const {
            path,
            label,
            icon,
            discarded,
          } = route;
          return (
            <MenuItem key={path}>
              <Link to={path}>
                {
                  getIcon(icon)
                }
                {label}
                {discarded
                && (
                <span className={styles.discarded}>
                  即将下线
                </span>
                )}
              </Link>
            </MenuItem>
          );
        })
      }
    </Menu>
  );
};

export default Nav;
