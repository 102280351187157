import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from 'react';
import {
  Form,
  Switch,
  InputNumber,
  TimePicker,
  Checkbox,
  Popover,
  Icon,
  Tooltip,
  Tabs,
  Button,
  message,
} from 'antd';
import { FormProps } from 'antd/lib/form';
import TopAlert from 'components/TopAlert';
import CheckTag from 'components/CheckTag';
import Tips from 'components/Tips';
import { createPortal } from 'react-dom';
import TemplateEditor, {
  NodeType,
  softBreakPlugin,
  footerPlugin,
  disabledPlugin,
} from 'components/TemplateEditor';
import moment from 'moment';
import SimpleSwitch from 'components/SimpleSwitch';
import TabCards from 'components/TabCards';
import { Portal } from 'components/Slot';
import { MainContext } from 'views/Main';
import cs from 'utils/classNames';
import segmentsToTexts from 'utils/segmentsToTexts';
import orderApi, { OrderReminder, ScriptConfigs } from 'apis/orderApi';
import judgeApi from 'apis/judgeApi';
import { SegmentBodyText, SegmentBodyConcept, TemplateSegment } from 'apis/paymentApi';
import ListItem from 'components/ListItem';
import { cards, serviceModes } from 'config/data';
import { ScriptLabel } from '.';
import styles from './styles.module.scss';
import exampleImage from './assets/example.png';

export type MarketingModes = 'serviceExperienceFirst'| 'marketingFirst' | 'merchantCustomization';

const {
  Item: FormItem,
} = Form;

const {
  TabPane,
} = Tabs;

const SCRIPT_LIMIT = 200;
const DEFAULT_TIMES = 2;

const formItemLayout = {
  labelCol: {
    md: { span: 4 },
    xl: { span: 4 },
  },
  wrapperCol: {
    md: { span: 20 },
    xl: { span: 20 },
  },
};

const buttonItemLayout = {
  wrapperCol: {
    md: { span: 20, offset: 4 },
  },
};

const plugins = [
  softBreakPlugin,
  footerPlugin,
  disabledPlugin,
];

interface Map<T> {
  [x: string]: T,
}

interface SettingForm extends FormProps {
  config: OrderReminder,
  inited: boolean,
  onCancel: () => void,
  onSuccess: (config: OrderReminder) => void,
}

const SettingForm = (props: SettingForm) => {
  const {
    config: configProp,
    inited,
    onCancel,
    onSuccess,
    className,
    ...otherProps
  } = props;

  const {
    scrollToTop,
  } = useContext(MainContext);

  const [enable, setEnable] = useState<boolean>(true);
  const [activeCard, setActiveCard] = useState<number>(0);

  const [times, setTimes] = useState<number>();
  const [scriptEnable, setScriptEnable] = useState<Map<boolean>>({});
  const [scriptHelp, setScriptHelp] = useState<Map<string>>({});
  const [scriptSegments, setScriptSegments] = useState<Map<TemplateSegment[]>>({});
  const [scriptForbiddens, setScriptForbiddens] = useState<Map<string[]>>({});
  const [activeTab, setActiveTab] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [custom, setCustom] = useState(configProp.merchantCustomization);

  const {
    serviceExperienceFirst,
    marketingFirst,
  } = configProp;
  const scriptsHelp = useMemo(() => {
    if (!times) {
      return '';
    }
    const enableCount = Object.values(scriptEnable).filter((enable) => enable).length;
    if (enableCount < times) {
      return '启用策略数量需大于等于【单次购物最多催促】次数';
    }
    return '';
  }, [times, scriptEnable]);

  useEffect(() => {
    if (!inited) {
      const {
        enable,
      } = configProp;

      setEnable(enable);
    }

    const {
      marketingMode,
      scriptConfigs,
    } = configProp;

    const currentConfig = configProp[marketingMode];
    setActiveCard(serviceModes.indexOf(marketingMode));

    const {
      times,
    } = currentConfig;

    const {
      enable,
      help,
      segments,
      forbiddens,
    } = Object.entries(scriptConfigs as ScriptConfigs).reduce(
      (res, [key, value], index) => {
        const {
          enable,
          templates: [{
            segments = [],
          } = {}] = [],
        } = value;

        if (index === 0) {
          setActiveTab(key);
        }

        return {
          ...res,
          enable: {
            ...res.enable,
            [key]: enable,
          },
          help: {
            ...res.help,
            [key]: '',
          },
          segments: {
            ...res.segments,
            [key]: segments,
          },
          forbiddens: {
            ...res.forbiddens,
            [key]: [],
          },
        };
      },
      {
        enable: {},
        help: {},
        segments: {},
        forbiddens: {},
      },
    );

    setScriptEnable(enable);
    setScriptHelp(help);
    setScriptSegments(segments);
    setScriptForbiddens(forbiddens);
    setTimes(times || DEFAULT_TIMES);
  }, [configProp, inited]);

  const scriptLength: Map<number> = useMemo(() => {
    return Object.entries(scriptSegments as Map<TemplateSegment[]>)
      .reduce((res, [key, segments]) => {
        return {
          ...res,
          [key]: (segments).reduce((res, segment) => {
            const {
              body: {
                type,
              },
            } = segment;

            switch (type) {
              case NodeType.TEXT:
                return res + (segment.body as SegmentBodyText).text.length;
              case NodeType.CONCEPT:
                return res + (segment.body as SegmentBodyConcept).concept.length;
              default:
                return res;
            }
          }, 0),
        };
      }, {});
  }, [scriptSegments]);

  const scriptLengthRef = useRef(scriptLength);

  useEffect(() => {
    scriptLengthRef.current = scriptLength;
  }, [scriptLength]);

  useEffect(() => {
    Object.entries(scriptLength).forEach(([key, length]) => {
      setScriptHelp((scriptHelp) => {
        const {
          [key]: forbiddens,
        } = scriptForbiddens;
        const {
          [key]: enable,
        } = scriptEnable;

        switch (true) {
          case !enable:
            return {
              ...scriptHelp,
              [key]: '',
            };
          case forbiddens.length !== 0:
            return {
              ...scriptHelp,
              [key]: `含有违禁词：${forbiddens.join('、')}，请修改后重试`,
            };
          case length > SCRIPT_LIMIT:
            return {
              ...scriptHelp,
              [key]: '话术超出字数限制',
            };
          case length === 0:
            return {
              ...scriptHelp,
              [key]: '请填写话术',
            };
          default:
            return {
              ...scriptHelp,
              [key]: '',
            };
        }
      });
    });
  }, [scriptForbiddens, scriptLength, scriptEnable]);

  const handleSegmentsChange = useCallback((key, segments) => {
    setScriptSegments((scriptSegments) => ({
      ...scriptSegments,
      [key]: segments,
    }));
    setScriptForbiddens((scriptForbiddens) => {
      return scriptForbiddens[key].length
        ? ({
          ...scriptForbiddens,
          [key]: [],
        })
        : scriptForbiddens;
    });
  }, []);

  const handleBlur = useCallback((key) => {
    if (scriptLengthRef.current[key] === 0) {
      setScriptHelp((help) => ({
        ...help,
        [key]: '请填写话术',
      }));
    }
  }, []);

  const handleSimpleSwitchChange = useCallback((key, checked) => {
    if (!checked) {
      setScriptHelp((help) => ({
        ...help,
        [key]: '',
      }));
    }
    setScriptEnable((enable) => ({
      ...enable,
      [key]: checked,
    }));
  }, []);

  const handleSubmit = useCallback(() => {
    if (enable && (Object.values(scriptHelp).filter((help) => help).length || scriptsHelp)) {
      scrollToTop();
      return;
    }

    setSubmitting(true);

    Promise.resolve(
      enable
        ? judgeApi
          .judge(
            Object.entries(scriptSegments)
              .filter(([key]) => scriptEnable[key])
              .map(([, segments]) => segmentsToTexts(segments)[0]),
          )
          .then((res) => {
            const forbiddenResult = Object.entries(res);

            if (!forbiddenResult.length) {
              return;
            }

            const keys = Object.keys(scriptHelp).filter((key) => scriptEnable[key]);
            const forbiddenMap = forbiddenResult.reduce((res, [keyIndex, forbiddens], index) => {
              const key = keys[parseInt(keyIndex, 10)];

              if (index === 0) {
                setActiveTab(key);
              }

              return {
                ...res,
                [key]: forbiddens,
              };
            }, {});

            setScriptForbiddens((scriptForbiddens) => ({
              ...scriptForbiddens,
              ...forbiddenMap,
            }));

            throw new Error('话术中包含违禁词');
          })
        : null,
    )
      .then(() => {
        const config = {
          enable,
          times,
          marketingMode: serviceModes[activeCard],
          marketingFirst,
          serviceExperienceFirst,
          merchantCustomization: custom,
          scriptConfigs: Object.entries(scriptEnable).reduce((res, [key, enable]) => {
            return {
              ...res,
              [key]: {
                enable,
                templates: [
                  {
                    ...((configProp.scriptConfigs as ScriptConfigs)[key].templates[0] || {}),
                    segments: scriptSegments[key],
                  },
                ],
              },
            };
          }, {}),
        };

        orderApi
          .update(config as OrderReminder)
          .then(onSuccess)
          .catch(() => {
            scrollToTop();
            message.error('服务器出问题了，别担心，这不是你的错，请稍后再尝试~');
          });
      })
      .catch(() => {
        scrollToTop();
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, [
    enable,
    scriptHelp,
    scriptsHelp,
    scriptSegments,
    scriptEnable,
    marketingFirst,
    serviceExperienceFirst,
    activeCard,
    configProp,
    custom,
    onSuccess,
    times,
    scrollToTop,
  ]);

  const handleTimesBlur = useCallback(() => {
    if (!times) {
      setTimes(DEFAULT_TIMES);
    }
  }, [times]);

  const ioBtn = createPortal(
    <Switch
      checkedChildren="已开启"
      unCheckedChildren="已关闭"
      checked={enable}
      onChange={setEnable}
      style={{ marginLeft: '10px' }}
    />,
    document.getElementById('io-portal') as Element,
  );

  function customEdit(key: MarketingModes) {
    console.log(configProp[key]);
    setCustom(configProp[key]);
    setActiveCard(2);
  }

  console.log(custom);

  return (
    <div
      className={enable ? '' : 'disabled'}
    >
      <Form
        {...formItemLayout}
        className={styles.form}
        {...otherProps}
      >
        {
        ioBtn
      }
        <Portal>
          <TopAlert
            buttonProps={{
              onClick: handleSubmit,
              loading: submitting,
            }}
          />
        </Portal>
        <ListItem title="Step1 下发模式选择">
          {
          activeCard === 2
          && (
          <Tips
            color="blue"
            content="根据您的店铺目标选择下方官方推荐的下发模式，有助于提高催单转化率"
          />
          )
        }
          <TabCards
            activeCard={activeCard}
            cards={cards}
            onChange={(idx) => setActiveCard(idx)}
          >

            {[
              <div key={1}>
                <FormItem
                  label="首次触发时间"
                >
                  <span>
              买家咨询结束
                    {serviceExperienceFirst.delaySeconds / 60}
              分钟后未下单时
                  </span>
                </FormItem>
                <FormItem
                  label={(
                    <span>
                    单次购物最多催促
                      <Tooltip
                        title="我们会根据不同的情况，在买家迟迟未付款时，进行有间隔的多次营销。设置多次时，需要至少勾选不少于该次数的营销策略。"
                      >
                        <Icon type="question-circle" style={{ marginLeft: '5px' }} />
                      </Tooltip>
                    </span>
                )}
                >
                  <span>
                    {serviceExperienceFirst.times}
              次
                  </span>
                </FormItem>
                <FormItem
                  label="客服工作时间段"
                >
                  <span>
                    {serviceExperienceFirst.assistantStartTime}
              -
                    {serviceExperienceFirst.assistantEndTime}
                  </span>
                </FormItem>
                <FormItem
                  label="不下发买家"
                >
                  <span>
                    {serviceExperienceFirst.receivingBuyersEnable && '接待中买家、'}
                    {serviceExperienceFirst.coolDownValue
              && `质保期买家（买家确认收货 ${serviceExperienceFirst.coolDownValue} 天内，视作质保期买家）`}
                  </span>
                </FormItem>

                <div className={styles.switchBtn} onClick={() => customEdit('serviceExperienceFirst')}>
                根据当前模式进行自定义配置
                  <Icon type="right-circle" />
                </div>
              </div>,
              <div key={2}>
                <FormItem
                  label="首次触发时间"
                >
                  <span>
              买家咨询结束
                    {marketingFirst.delaySeconds / 60}
              分钟后未下单时
                  </span>
                </FormItem>
                <FormItem
                  label={(
                    <span>
                    单次购物最多催促
                      <Tooltip
                        title="我们会根据不同的情况，在买家迟迟未付款时，进行有间隔的多次营销。设置多次时，需要至少勾选不少于该次数的营销策略。"
                      >
                        <Icon type="question-circle" style={{ marginLeft: '5px' }} />
                      </Tooltip>
                    </span>
                )}
                >
                  <span>
                    {marketingFirst.times}
              次
                  </span>
                </FormItem>
                <FormItem
                  label="客服工作时间段"
                >
                  <span>
                    {marketingFirst.assistantStartTime}
              -
                    {marketingFirst.assistantEndTime}
                  </span>
                </FormItem>
                <FormItem
                  label="不下发买家"
                >
                  <span>
                    {marketingFirst.receivingBuyersEnable && '接待中买家 '}
                    {marketingFirst.coolDownValue
              && `质保期买家（买家确认收货 ${marketingFirst.coolDownValue} 天内，视作质保期买家）`}
                  </span>
                </FormItem>

                <div className={styles.switchBtn} onClick={() => customEdit('marketingFirst')}>
            根据当前模式进行自定义配置
                  <Icon type="right-circle" />
                </div>
              </div>,
              <div key={3}>
                <FormItem
                  label="首次触发时间"
                  required
                >
                  <span>咨询结束</span>
                  <InputNumber
                    min={1}
                    max={60}
                    precision={0}
                    disabled={!enable}
                    value={custom.delaySeconds / 60}
                    onChange={(val) => setCustom({ ...custom, delaySeconds: (val || 2) * 60 })}
                  />
                  <span>分钟未下单时</span>
                  <span className={styles.light}>（可输入1min~60min）</span>
                </FormItem>
                <FormItem
                  label={(
                    <>
                      <span>单次购物最多催促</span>
                      <Tooltip
                        title={(
                          <>
                            <div>我们会根据不同的情况，在买家迟迟未下单时，进行有间隔的多次营销。</div>
                            <div>设置多次时，需要至少勾选不少于该次数的营销策略。</div>
                          </>
                        )}
                      >
                        <Icon
                          className={styles['label-icon']}
                          type="question-circle"
                        />
                      </Tooltip>
                    </>
                )}
                  required
                >
                  <InputNumber
                    min={1}
                    max={3}
                    precision={0}
                    disabled={!enable}
                    value={custom.times}
                    onChange={(times) => times && setCustom({ ...custom, times })}
                    onBlur={handleTimesBlur}
                  />
                  <span>次</span>
                  <span className={styles.light}>（可输入1次~3次）</span>
                </FormItem>
                <FormItem
                  label="客服工作时间段"
                  required
                >
                  <TimePicker
                    format="HH:mm"
                    value={moment(`1970-01-01 ${custom.assistantStartTime}`)}
                    disabled={!enable}
                    allowClear={false}
                    onChange={(val) => setCustom({ ...custom, assistantStartTime: val.format('HH:mm') })}
                  />
            &nbsp;→
                  <TimePicker
                    format="HH:mm"
                    allowClear={false}
                    disabled={!enable}
                    value={moment(`1970-01-01 ${custom.assistantEndTime}`)}
                    onChange={(val) => setCustom({ ...custom, assistantEndTime: val.format('HH:mm') })}
                  />
                  <div className={styles.light} style={{ lineHeight: 1.7, maxWidth: '400px' }}>
                为保证买家的购物体验，避免营销拉回的买家无人接待，您可以配置客服工作时间，我们将不会在客服的工作时间之外进行营销下发。
                  </div>
                </FormItem>
                <FormItem
                  label="不下发买家"
                >
                  <Checkbox
                    checked={custom.receivingBuyersEnable}
                    disabled={!enable}
                    onChange={() => setCustom({
                      ...custom,
                      receivingBuyersEnable: !custom.receivingBuyersEnable,
                    })}
                  >
              接待中买家
                  </Checkbox>
                  <Checkbox
                    checked={custom.coolDownEnable} // cooldown表示质保期
                    disabled={!enable}
                    onChange={() => setCustom({
                      ...custom,
                      coolDownEnable: !custom.coolDownEnable,
                    })}
                  >
              质保期买家
                  </Checkbox>
            （买家确认收货
                  {' '}
                  <InputNumber
                    min={1}
                    max={60}
                    value={custom.coolDownValue}
                    disabled={!enable}
                    onChange={(val) => setCustom({
                      ...custom,
                      coolDownValue: Math.floor(val || 1),
                    })}
                  />
                  {' '}
            天内，视作质保期买家）
                </FormItem>
              </div>,
            ]}
          </TabCards>
        </ListItem>

        <ListItem title="Step 2 编辑下发内容" style={{ width: '1000px' }}>
          <FormItem
            label="营销方式"
            labelAlign="left"
            required
          >
            <CheckTag disabled={!enable}>
              <span>智能化催下单</span>
              <Tooltip title="言准基于大数据和自动化技术，对每位顾客进行智能化跟单，引导买家下单">
                <Icon
                  className={styles['tag-icon']}
                  type="question-circle"
                />
              </Tooltip>
            </CheckTag>
            <span className={styles.light}>以下营销策略，启用数量必须不少于【单次购物最多催促次数】</span>
          </FormItem>

          {
        enable && (
          <FormItem
            {...buttonItemLayout}
            validateStatus={scriptsHelp ? 'error' : undefined}
            help={scriptsHelp}
          >
            <div
              className={cs([
                styles['tabs-wrapper'],
                { [styles.invalid]: scriptsHelp },
              ])}
            >
              <Tabs
                type="card"
                className={styles.tabs}
                activeKey={activeTab}
                onChange={setActiveTab}
              >
                {
                  Object.entries(scriptEnable).map(([key, enable]) => {
                    return (
                      <TabPane
                        key={key}
                        tab={(
                          <>
                            <span
                              className={cs([
                                styles.point,
                                enable ? styles['bg-green'] : styles['bg-grey'],
                              ])}
                            />
                            <span className={scriptHelp[key] ? styles.red : undefined}>
                              {ScriptLabel[key]}
                            </span>
                          </>
                        )}
                      >
                        <div className={styles.desc}>
                          <span>系统会自动将下方的话术内容发送给买家</span>
                          <Popover
                            placement="bottomLeft"
                            content={(
                              <>
                                <div>消费者侧效果示例</div>
                                <img
                                  alt="效果示例"
                                  src={exampleImage}
                                  width={300}
                                />
                              </>
                            )}
                          >
                            <span className={cs([styles.example, styles.ml20])}>查看示例</span>
                          </Popover>
                        </div>
                        <FormItem
                          validateStatus={scriptHelp[key] ? 'error' : undefined}
                          help={scriptHelp[key]}
                        >
                          {
                            key === activeTab && (
                              <TemplateEditor
                                value={scriptSegments[key]}
                                onChange={(segments: any) => handleSegmentsChange(key, segments)}
                                onBlur={() => handleBlur(key)}
                                limit={SCRIPT_LIMIT}
                                validateStatus={scriptHelp[key] ? 'error' : undefined}
                                stringLength={scriptLength[key]}
                                disabled={!enable}
                                plugins={plugins}
                              />
                            )
                          }
                        </FormItem>
                        <SimpleSwitch
                          checked={enable}
                          onChange={(checked) => handleSimpleSwitchChange(key, checked)}
                        />
                      </TabPane>
                    );
                  })
                }
              </Tabs>
            </div>
          </FormItem>
        )
      }
        </ListItem>
        <div className={styles['sticky-toolbar']}>
          <Button
            disabled={!inited || submitting}
            onClick={onCancel}
          >
          取消
          </Button>
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={submitting}
          >
          保存
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SettingForm;
