import React from 'react';
import styles from './styles.module.scss';

interface ModalProps {
  show: boolean
  children: React.ReactChildren | React.ReactNode
}

export default function Modal(props: ModalProps) {
  const {
    show,
    children,
  } = props;
  return (
    show
      ? (
        <div className={styles.mask}>
          <div className={styles.modal}>
            {children}
          </div>
        </div>
      )
      : null
  );
}
