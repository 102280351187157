import React, {
  lazy,
  Component,
  ReactNode,
  FunctionComponent,
} from 'react';
import { Icon } from 'antd';
import { ReactComponent as PaymentSvg } from './assets/iconPayment.svg';
import { ReactComponent as GroupSvg } from './assets/iconGroup.svg';
import { ReactComponent as OrderSvg } from './assets/iconOrder.svg';
import { ReactComponent as PraiseSvg } from './assets/iconPraise.svg';
import Order from '../Order';

const Payment = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "payment"  */ '../Payment'));
const Group = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "group"  */ '../Group'));
const Praise = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "praise"  */ '../Praise'));
// const Order = lazy(() => import('../Order'));

export interface Route {
  path: string,
  label: ReactNode,
  icon?: string | ReactNode,
  discarded?: boolean,
  component: Component | FunctionComponent | any,
}

const routes: Route[] = [
  {
    path: '/order',
    label: '智能催下单',
    icon: <Icon component={OrderSvg} />,
    component: Order,
  },
  {
    path: '/payment',
    label: '智能催付款',
    icon: <Icon component={PaymentSvg} />,
    component: Payment,
  },
  {
    path: '/group',
    label: '智能促成团',
    icon: <Icon component={GroupSvg} />,
    discarded: true,
    component: Group,
  },
];
export const grayRoutes = [{
  path: '/praise',
  label: '智能催好评',
  icon: <Icon component={PraiseSvg} />,
  component: Praise,
}];
export default routes;
