import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import {
  Card,
  DatePicker,
  Button,
  Icon,
  Tooltip,
  message,
} from 'antd';
import {
  Chart,
  Legend,
  Axis,
  Tooltip as ChartTip,
  Geom,
  View,
} from 'bizcharts';
import DataSet from '@antv/data-set';
import moment, { Moment } from 'moment';
import XLSX from 'xlsx';
import Lock from 'components/Lock';
import cs from 'utils/classNames';
import orderApi, { StatsItem, StatsTotal } from 'apis/orderApi';
import TableModal from '../TableModal';
import { OrderContext } from '..';
import styles from './styles.module.scss';

const {
  RangePicker,
} = DatePicker;

const LabelMap: Record<any, string> = {
  date: '日期',
  orderAmount: '挽回金额（元）',
  orderNum: '挽回单量',
  replyNum: '拉动回复次数',
  payRate: '挽回下单率',
  replyRate: '拉动回复率',
};

const scale = {
  orderAmount: {
    range: [0, 0.9],
    alias: '挽回金额（元）',
  },
  value: {
    range: [0, 0.9],
  },
  date: {
    range: [0.05, 0.95],
  },
};

const legendItems: any[] = [
  {
    value: LabelMap.orderNum,
    marker: {
      symbol: 'square',
      fill: '#FF9B67',
      radius: 5,
    },
  },
  {
    value: LabelMap.replyNum,
    marker: {
      symbol: 'square',
      fill: '#F7B634',
      radius: 5,
      lineWidth: 3,
    },
  },
  {
    value: LabelMap.orderAmount,
    marker: {
      symbol: 'square',
      fill: '#5D8EFA',
      radius: 5,
      lineWidth: 3,
    },
  },
];

const Detail = () => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [range, setRange] = useState<[Moment, Moment]>([moment().subtract(6, 'days'), moment()]);
  const [total, setTotal] = useState<StatsTotal>();
  const [data, setData] = useState<StatsItem[]>();
  const [chart, setChart] = useState();

  const {
    inited,
    editing,
  } = useContext(OrderContext);

  const chartData = useMemo(() => {
    if (!data) {
      return null;
    }

    const ds = new DataSet();
    ds.setState('type', '');
    const dv = ds.createView();
    dv
      .source(data.map((item) => ({
        ...item,
        orderAmount: parseFloat(item.orderAmount),
      })))
      .transform({
        type: 'fold',
        fields: ['orderNum', 'replyNum'],
        key: 'type',
        value: 'value',
      });
    return dv;
  }, [data]);

  useEffect(() => {
    orderApi
      .stats({
        start: range[0].format('YYYY-MM-DD'),
        end: range[1].format('YYYY-MM-DD'),
      })
      .then((res) => {
        const {
          stats: {
            data,
            ...total
          },
        } = res;

        setTotal(total);
        setData(data);
      })
      .catch(() => {
        message.error('获取收益数据失败');
      });
  }, [range]);

  const isRange = useCallback(([start, end]) => {
    return range[0].isSame(start, 'day') && range[1].isSame(end, 'day');
  }, [range]);

  const handleDownloadClick = useCallback(() => {
    if (!data) {
      return;
    }
    const book = XLSX.utils.book_new();
    const detailSheet = XLSX.utils.json_to_sheet(data.map((item) => {
      return Object.entries(LabelMap).reduce((res, [key, value]) => {
        return {
          ...res,
          [value]: item[key as keyof StatsItem],
        };
      }, {});
    }));
    XLSX.utils.book_append_sheet(book, detailSheet);
    XLSX.writeFile(book, '收益数据.xlsx', {
      bookType: 'xlsx',
    });
  }, [data]);

  return (
    <Card
      title="收益情况"
      className={cs([
        styles.card,
        { [styles.hide]: editing },
      ])}
    >
      <div className={styles.controller}>
        <span>
          <Button
            type="link"
            disabled={!inited}
            className={cs([(!isRange([moment().subtract(6, 'days'), moment()]) && inited) && styles.nagtive])}
            onClick={() => setRange([moment().subtract(6, 'days'), moment()])}
          >
            近 7 天
          </Button>
          <Button
            type="link"
            disabled={!inited}
            className={cs([(!isRange([moment().subtract(30, 'days'), moment()]) && inited) && styles.nagtive])}
            onClick={() => setRange([moment().subtract(30, 'days'), moment()])}
          >
            近 31 天
          </Button>
          <RangePicker
            value={range}
            onChange={(dates) => setRange(dates as [Moment, Moment])}
            disabled={!inited}
            allowClear={false}
            disabledDate={(current) => {
              if (
                (current as Moment).isAfter(moment())
                || (current as Moment).isBefore(moment().subtract(31, 'days'))
              ) {
                return true;
              }
              return false;
            }}
          />
        </span>
        <span>
          <Button
            icon="download"
            type="link"
            disabled={!inited || !data}
            onClick={handleDownloadClick}
          >
            下载
          </Button>
          <Button
            icon="profile"
            type="link"
            disabled={!inited}
            onClick={() => setModalVisible(true)}
          >
            查看转化明细
          </Button>
        </span>
      </div>
      <Lock spinning={!inited}>
        <div className={styles.detail}>
          {
            total && (
              <div className={styles.total}>
                <span>
                  <span>挽回总金额（元）</span>
                  <span className={styles['light-blue']}>{total.totalOrderAmount}</span>
                </span>
                <span>
                  <span>挽回总单量</span>
                  <span className={styles.orange}>{total.totalOrderNum}</span>
                </span>
                <span>
                  <span>拉动回复次数</span>
                  <span className={styles.yellow}>{total.totalReplyNum}</span>
                </span>
                <span>
                  <span>
                    挽回下单率
                    <Tooltip title="归因下单独立买家数/营销触达的独立买家数">
                      <Icon type="question-circle" />
                    </Tooltip>
                  </span>
                  <span className={styles.purple}>{`${total.totalPayRate}%`}</span>
                </span>
                <span>
                  <span>
                    拉动回复率
                    <Tooltip title="归因回复独立买家数/营销触达的独立买家数">
                      <Icon type="question-circle" />
                    </Tooltip>
                  </span>
                  <span className={styles.purple}>{`${total.totalReplyRate}%`}</span>
                </span>
              </div>
            )
          }
          {
            data && (
              <Chart
                scale={scale}
                data={chartData}
                height={300}
                padding="auto"
                onGetG2Instance={setChart}
                forceFit
              >
                <Legend
                  custom
                  allowAllCanceled
                  position="top-center"
                  items={legendItems}
                  onClick={(e: any) => {
                    if (!chart) {
                      return;
                    }

                    const { item } = e;
                    const { value } = item;
                    const { checked } = e;

                    if (value === LabelMap.orderAmount) {
                      if (checked) {
                        chart.get('views')[0].get('geoms')[0].show();
                      } else {
                        chart.get('views')[0].get('geoms')[0].hide();
                      }
                    }

                    const newLegend = legendItems.map((item) => {
                      if (item.value === value) {
                        return {
                          ...item,
                          checked,
                        };
                      }
                      return item;
                    });

                    chart.filter('type', (type: any) => {
                      const legendConfig = newLegend.find((item) => item.value === LabelMap[type]);
                      return legendConfig && legendConfig.checked;
                    });

                    chart.legend({
                      items: newLegend,
                    });

                    chart.repaint();
                  }}
                />
                <Axis
                  name="value"
                  position="left"
                  grid={null}
                  label={{
                    autoRotate: false,
                  }}
                />
                <ChartTip />
                <Geom
                  type="point"
                  position="date*value"
                  size={2}
                  shape="circle"
                  color={[
                    'type',
                    (value) => {
                      if (value === 'orderNum') {
                        return '#FF9B67';
                      }
                      if (value === 'replyNum') {
                        return '#F7B634';
                      }
                      return '';
                    },
                  ] as [string, (x: any) => string]}
                />
                <Geom
                  type="line"
                  position="date*value"
                  size={3}
                  color={[
                    'type',
                    (value) => {
                      if (value === 'orderNum') {
                        return '#FF9B67';
                      }
                      if (value === 'replyNum') {
                        return '#F7B634';
                      }
                      return '';
                    },
                  ] as [string, (x: any) => string]}
                  tooltip={[
                    'date*value*type',
                    (date, value, type) => {
                      return {
                        title: date,
                        name: type === 'orderNum' ? '挽回单量' : '拉动回复次数',
                        value,
                      };
                    },
                  ]}
                />
                <View data={chartData}>
                  <Axis
                    name="orderAmount"
                    position="right"
                    grid={{
                      lineStyle: {
                        lineDash: [1, 1],
                      },
                      hideLastLine: true,
                    }}
                    title={{
                      position: 'end',
                      offset: 30,
                      textStyle: {
                        rotate: 0,
                      },
                    }}
                    label={{
                      autoRotate: false,
                    }}
                  />
                  <Geom
                    type="interval"
                    position="date*orderAmount"
                    color="#5D8EFA"
                    tooltip={[
                      'date*orderAmount',
                      (date, orderAmount) => {
                        return {
                          title: date,
                          name: '挽回金额（元）',
                          value: orderAmount,
                        };
                      },
                    ]}
                  />
                </View>
              </Chart>
            )
          }
        </div>
      </Lock>
      <TableModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
      />
    </Card>
  );
};

export default Detail;
