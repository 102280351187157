/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
interface Config {
  [x: string]: boolean
}

export function formatStr(config: Config):string {
  // 全部没开启显示无
  if (Object.values(config).every((enable) => !enable)) {
    return '无';
  }
  return Object.entries(config).filter(([key, enable]) => {
    return enable;
  }).map(([key]) => key)
    .join('、');
}
