import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface DisplayCardProps {
  children: ReactNode,
  footer?: ReactNode
}

export default function DisplayCard(props: DisplayCardProps) {
  const {
    children,
    footer,
  } = props;
  return (
    <div className={styles.displayCard}>
      { children }
      <div className={styles.footer}>
        { footer }
      </div>
    </div>
  );
}
