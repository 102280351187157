export const cards = [
  {
    title: '优先服务体验，不过多打扰买家',
    subTitle: '均衡下发与买家体验，更加提升买家综合满意度',
  },
  {
    title: '尽可能多发营销',
    subTitle: '减少下发限制，尽量可能多发营销，提升营销收益',
  },
  {
    title: '自定义模式',
    subTitle: '配置项不受限，自由定制',
  },
];

export const ServiceMode = {
  serviceExperienceFirst: '优先服务体验，不过多打扰买家',
  marketingFirst: '尽可能多发营销',
  merchantCustomization: '自定义模式',
  officialRecommend: '优先服务体验，不过多打扰买家',
};

export const serviceModes = ['serviceExperienceFirst', 'marketingFirst', 'merchantCustomization'];
