import React from 'react';
import cs from 'utils/classNames';
import styles from './styles.module.scss';


interface NoticeProps {
  title?: React.ReactNode | string,
  layout?: 'horizontal' | 'vertical'
  body?: React.ReactNode,
  className?: string,
  children?: React.ReactNode
  style?: React.CSSProperties
}
export default function Notice(props: NoticeProps) {
  const {
    title,
    body,
    layout = 'horizontal',
    className,
    children,
    ...others
  } = props;

  return (
    <div
      className={cs(
        styles.notice,
        className,
        {
          [styles.vertical]: layout === 'vertical',
        },
      )}
      {...others}
    >
      <div>
        {title}
      </div>
      <div className={styles.body}>
        {body}
      </div>
      {children}
    </div>
  );
}
