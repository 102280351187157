import React, { ReactNode } from 'react';
import { Alert, Icon, Button } from 'antd';
import { AlertProps } from 'antd/lib/alert';
import { ButtonProps } from 'antd/lib/button';
import cs from 'utils/classNames';
import styles from './styles.module.scss';

interface TopAlertProps extends Omit<AlertProps, 'message'> {
  buttonProps?: ButtonProps,
  message?: string | ReactNode,
}

const TopAlert = (props: TopAlertProps) => {
  const {
    className,
    buttonProps,
    ...otherProps
  } = props;

  return (
    <Alert
      className={cs([className, styles.alert])}
      type="warning"
      showIcon
      banner
      icon={(
        <Icon
          type="bell"
          theme="filled"
        />
      )}
      message={(
        <>
          <span className={styles.text}>场景设置编辑完成后点击保存才可生效</span>
          <Button
            type="primary"
            size="small"
            {...buttonProps}
          >
            立即保存
          </Button>
        </>
      )}
      {...otherProps}
    />
  );
};

export default TopAlert;
