import React, { useState, createContext } from 'react';
import Setting from './Setting';
import Detail from './Detail';

interface OrderContextValue {
  inited: boolean
  setInited: (inited: boolean) => void
  editing: boolean
  setEditing: (editing: boolean) => void
}

export const OrderContext = createContext<OrderContextValue>({} as OrderContextValue);

const Order = () => {
  const [inited, setInited] = useState<boolean>(true);
  const [editing, setEditing] = useState<boolean>(false);

  return (
    <OrderContext.Provider
      value={{
        inited,
        setInited,
        editing,
        setEditing,
      }}
    >
      <Setting />
      <Detail />
    </OrderContext.Provider>
  );
};

export default Order;
