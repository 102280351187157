import { useMemo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { decode } from 'jsonwebtoken';
import { parse } from 'query-string';
import { STORAGE_TOKEN_NAME } from 'config/react-app';
import { authorize } from 'apis/clients';
import turnstileApi from 'apis/turnstileApi';
import { message } from 'antd';

/* eslint-disable camelcase */
interface AuthInfo {
  nick: string,
  leyan_user?: any,
  store_id: string,
  shop_id: number,
  env: string,
  store_name: string,
  features: any,
  legacy: boolean,
  iss: string,
  iat: number,
  exp: number,
}

export interface Collection {
  label: string,
  name: string,
}

interface Auth {
  authorizing: boolean,
  authorized: boolean,
  authInfo?: AuthInfo,
  collections: Array<Collection>,
}

const useAuth = (): Auth => {
  const [authorizing, setAuthorizing] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const [collections, setCollections] = useState();

  const { search } = useLocation();
  const { auth_token: queryAuthToken } = useMemo(() => parse(search), [search]);
  const [storageAuthToken, setStorageAuthToken] = useLocalStorage(STORAGE_TOKEN_NAME);

  const authToken = useMemo(() => {
    let authToken = '';

    if (queryAuthToken && typeof queryAuthToken === 'string') {
      setStorageAuthToken(queryAuthToken);
      authToken = queryAuthToken;
    } else if (storageAuthToken) {
      authToken = storageAuthToken;
    }

    return authToken;
  }, [queryAuthToken, setStorageAuthToken, storageAuthToken]);

  const authInfo = useMemo(() => decode(authToken) as AuthInfo, [authToken]);

  const isValid = useMemo(() => {
    if (!authInfo) {
      return false;
    }

    const { exp } = authInfo;
    if (Date.now() > exp * 1e3) {
      return false;
    }

    return true;
  }, [authInfo]);

  useEffect(() => {
    if (authToken && isValid) {
      authorize(authToken);

      turnstileApi
        .shop()
        .then((res = {}) => {
          const {
            shop: {
              collections = [],
            } = {},
          } = res;

          setCollections(collections);
          setAuthorized(true);
        })
        .catch(() => {
          setAuthorizing(false);
          setAuthorized(false);
          message.error('获取店铺信息失败');
        })
        .finally(() => {
          setAuthorizing(false);
        });
    } else {
      setAuthorized(false);
      setAuthorizing(false);
    }
  }, [authToken, isValid]);

  return {
    authorizing,
    authorized,
    authInfo,
    collections,
  };
};

export default useAuth;
