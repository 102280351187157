import React, { ReactNode, HTMLAttributes } from 'react';
import { Editor } from 'slate';
import {
  TemplateSegment,
  SegmentBodyText,
  SegmentBodyConcept,
  // eslint-disable-next-line
} from 'apis/paymentApi';
// eslint-disable-next-line
import { NodeType } from 'components/TemplateEditor';
import { Concept } from '../conceptPlugin';

interface ReadOnlyComponent extends HTMLAttributes<HTMLDivElement> {
  segments: TemplateSegment[],
}

export const ReadOnlyComponent = (props: ReadOnlyComponent) => {
  const {
    segments,
    ...otherProps
  } = props;

  return (
    <div {...otherProps}>
      {
        (segments as TemplateSegment[]).map((segment, index) => {
          const {
            body: {
              type,
            },
          } = segment;
          switch (type) {
            case NodeType.TEXT: {
              const {
                text,
              } = segment.body as SegmentBodyText;
              // eslint-disable-next-line
              return <span key={index}>{text}</span>;
            }
            case NodeType.CONCEPT: {
              const {
                concept,
              } = segment.body as SegmentBodyConcept;
              return (
                <Concept
                  // eslint-disable-next-line
                  key={index}
                  concept={concept}
                />
              );
            }
            default:
              return null;
          }
        })
      }
    </div>
  );
};

const ReadOnly = () => {
  const renderEditor = (props: any, editor: Editor, next: () => ReactNode) => {
    const {
      segments,
      readOnly,
    } = props;

    const readOnlyEditor = <ReadOnlyComponent segments={segments} />;

    return readOnly ? readOnlyEditor : next();
  };

  return {
    renderEditor,
  };
};

export default ReadOnly;
