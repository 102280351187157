import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface ListItemProps {
  title: string,
  style?: React.CSSProperties
  className?: string
  children: ReactNode[] | ReactNode
}

function ListItem(props: ListItemProps) {
  const {
    title,
    children,
    ...rest
  } = props;

  return (
    <div {...rest}>
      <div className={styles.listItemTitle}>{title}</div>
      <div className={styles.listItemBody}>
        {children}
      </div>
    </div>
  );
}

export default ListItem;
