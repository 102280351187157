import history from 'utils/history';
import ApiError from './ApiError';

const respHandler = (resp: any) => {
  const { status } = resp;
  switch (status) {
    case 200:
    case 204:
      return resp;
    default:
      return Promise.reject(
        new ApiError(resp.data.err_msg || '服务器错误', status),
      );
  }
};
const errostatusHandler = (error: any) => {
  const {
    response: { status },
  } = error;
  switch (status) {
    case 401:
      history.push('/401');
      break;
    case 403:
      history.push('/403');
      break;
    default:
      break;
  }
  return Promise.reject(new ApiError(error.message, status));
};
export default [respHandler, errostatusHandler];
