import React, { ReactNode } from 'react';
import { Editor } from 'slate';

interface FooterPluginProps {
  render: (...props: any[]) => ReactNode,
}

const FooterPlugin = (props: FooterPluginProps) => {
  const {
    render,
  } = props;

  const renderEditor = (props: any, editor: Editor, next: () => ReactNode) => {
    return (
      <>
        {next()}
        {render(editor, props)}
      </>
    );
  };

  return {
    renderEditor,
  };
};

export default FooterPlugin;
