import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
} from 'react';
import Tips from 'components/Tips';
import { Card, Button, message } from 'antd';
import orderApi, { OrderReminder } from 'apis/orderApi';
import SettingDisplay from './SettingDisplay';
import SettingForm from './SettingForm';
import { OrderContext } from '..';
import styles from './styles.module.scss';

export const ScriptKey = {
  NOT_PAID: 'reminderBuyerNotOrder',
  LIMITED: 'reminderStockLimited',
  FIRST_PAY: 'firstPayFirstShip',
  GUARANTEE: 'makeSureQuality',
};

export const ScriptLabel = {
  [ScriptKey.NOT_PAID]: '提醒买家还未下单',
  [ScriptKey.LIMITED]: '提醒库存有限',
  [ScriptKey.FIRST_PAY]: '说明先付款先发货',
  [ScriptKey.GUARANTEE]: '商品质量有保证',
};

const Setting = () => {
  const [config, setConfig] = useState<OrderReminder>();

  const {
    inited,
    setInited,
    editing,
    setEditing,
  } = useContext(OrderContext);

  useEffect(() => {
    orderApi
      .fetch()
      .then((res) => {
        if (
          !res.scriptConfigs
          || Object.values(res.scriptConfigs).every(({ enable }) => !enable)
        ) {
          setInited(false);
        } else {
          setConfig(res);
        }
      })
      .catch(() => {
        message.error('获取配置信息失败');
      });
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!inited) {
      setEditing(true);
    }
  }, [inited, setEditing]);

  const handleSubmitSuccess = useCallback((config) => {
    setConfig(config);
    setEditing(false);
    message.success('设置保存成功');
  }, [setEditing]);

  return (
    <Card
      className="card"
      title={(
        <span>
          智能催下单
          <span id="io-portal" />
        </span>
      )}
      loading={!config}
    >
      {
        editing ? (
          <SettingForm
            inited={inited}
            config={config as OrderReminder}
            onCancel={() => setEditing(false)}
            onSuccess={handleSubmitSuccess}
          />
        ) : (
          <>
            {config?.marketingMode === 'merchantCustomization' && config?.enable && (
            <Tips
              color="blue"
              content={(
                <span>
                  根据您的店铺目标选择下方官方推荐的下发模式，有助于提高催单转化率
                  <Button
                    onClick={() => setEditing(true)}
                    type="primary"
                    size="small"
                    style={{ marginLeft: '10px' }}
                  >
                查看官方模式
                  </Button>
                </span>
              )}
            />
            )}

            {
             !config?.enable && (
             <Tips
               className={styles.tips}
               content={(
                 <span>
                   开启场景，有助于创造更多的售前销售机会、并提高店铺的进店转化率，自动催下单，也会大大节省客服人力。
                   <Button
                     type="link"
                     onClick={() => setEditing(true)}
                   >
                    立即启用
                   </Button>
                 </span>
               )}
             />
             )
            }
            <SettingDisplay
              config={config as OrderReminder}
              updateEditing={setEditing}
            />
          </>
        )
      }
    </Card>
  );
};

export default Setting;
