import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { Editor } from 'slate-react';
import { Icon, Modal } from 'antd';
import {
  TemplateSegment,
  SegmentBodyText,
  SegmentBodyImage,
  SegmentBody,
  SegmentBodyConcept,
} from 'type';
import {
  Value,
  ValueJSON,
  Node,
  Inline,
} from 'slate';
// eslint-disable-next-line
import { List } from 'immutable';
import { isEqual } from 'lodash-es';
// @ts-ignore
import SoftBreakPlugin from 'slate-soft-break';
import ConceptPlugin from 'utils/slatePlugins/conceptPlugin';
import ToolbarPlugin from 'utils/slatePlugins/toolbarPlugin';
import FooterPlugin from 'utils/slatePlugins/footerPlugin';
import ReadOnlyPlugin, { ReadOnlyComponent } from 'utils/slatePlugins/readOnlyPlugin';
import DisabledPlugin from 'utils/slatePlugins/disabledPlugin';
import cs from 'utils/classNames';
import InsertGroupLinkButton from './InsertGroupLinkButton';
import styles from './styles.module.scss';

export const NodeType = {
  ROOT: 'root',
  TEXT: 'text',
  IMAGE: 'image',
  CONCEPT: 'concept',
};

const SegmentToNode = {
  [NodeType.TEXT]: (segment: TemplateSegment) => {
    const {
      text,
    } = segment.body as SegmentBodyText;

    return {
      object: 'text',
      text,
    };
  },
  [NodeType.IMAGE]: (segment: TemplateSegment) => {
    const {
      type,
      ...data
    } = segment.body as SegmentBodyImage;

    return {
      object: 'inline',
      data,
      type,
    };
  },
  [NodeType.CONCEPT]: (segment: TemplateSegment) => {
    const {
      type,
      ...data
    } = segment.body;

    return {
      object: 'inline',
      type,
      data,
    };
  },
};

const segmentsToEditorValue = (segments: TemplateSegment[]): Value => {
  const nodes = segments.map((segment) => {
    const {
      body: {
        type,
      },
    } = segment;

    return SegmentToNode[type](segment);
  });

  return Value.fromJS({
    document: {
      nodes: [{
        object: 'block',
        type: NodeType.ROOT,
        nodes,
      }],
    },
  } as ValueJSON);
};

const nodeToSegments = (
  node: Node | List<any>,
  prevNode?: Node | List<any> | null,
  segments = [],
): any => {
  if (List.isList(node)) {
    return (node as List<any>).reduce((result, childNode, index) => {
      return nodeToSegments(
        childNode as Node,
        (index as Number) > 0 ? (node as List<any>).get((index as number) - 1) : null,
        result as any,
      );
    }, segments);
  }

  const {
    object,
  } = (node as Node);

  switch (object) {
    case 'text': {
      const {
        text,
      } = (node as Node);

      if (text === '') {
        return segments;
      }

      const lastIndex = segments.length - 1;
      const {
        [lastIndex]: lastSegment,
      } = segments;

      return lastSegment && (lastSegment as SegmentBody).type === NodeType.TEXT
        ? [
          ...segments.slice(0, lastIndex),
          {
            type: NodeType.TEXT,
            text: `${(lastSegment as SegmentBodyText).text}${text}`,
          },
        ]
        : [
          ...segments,
          {
            type: NodeType.TEXT,
            text,
          },
        ];
    }
    case 'block': {
      if (!prevNode) {
        return nodeToSegments((node as any).nodes, null, segments);
      }

      const lastIndex = segments.length - 1;
      const {
        [lastIndex]: lastSegment,
      } = segments;

      const _segments = (lastSegment && (lastSegment as SegmentBody).type === NodeType.TEXT)
        ? [
          ...segments.slice(0, lastIndex),
          {
            type: NodeType.TEXT,
            text: `${(lastSegment as SegmentBodyText).text}\n`,
          },
        ]
        : [
          ...segments,
          {
            type: NodeType.TEXT,
            text: '\n',
          },
        ];

      return nodeToSegments(
        (node as any).nodes,
        null,
        _segments as never[],
      );
    }
    case 'inline': {
      switch ((node as Inline).type) {
        case NodeType.CONCEPT: {
          const {
            data,
          } = node as Inline;

          return [
            ...segments,
            {
              type: NodeType.CONCEPT,
              concept: data.get('concept'),
            },
          ];
        }
        case NodeType.IMAGE: {
          const {
            data,
          } = node as Inline;

          return [
            ...segments,
            {
              type: NodeType.IMAGE,
              imageId: data.get('imageId'),
              fallBack: data.get('fallBack'),
              label: data.get('label'),
            },
          ];
        }
        default: {
          return nodeToSegments((node as any).nodes, null, segments);
        }
      }
    }
    default: {
      return segments;
    }
  }
};

const editorValuetoSegments = (value: Value): TemplateSegment[] => {
  const {
    document: {
      nodes,
    },
  } = value;

  const segments: SegmentBody[] = nodeToSegments(nodes);
  return segments.map((segmentBody, index) => ({
    header: {
      sequenceNumber: index,
    },
    body: {
      ...segmentBody,
      linkTo: '',
    },
  }));
};

export const getSegmentsLength = (segments: TemplateSegment[]) => {
  return segments.reduce((res, segment) => {
    const {
      body: {
        type,
      },
    } = segment;

    switch (type) {
      case NodeType.TEXT:
        return res + (segment.body as SegmentBodyText).text.length;
      case NodeType.CONCEPT:
        return res + (segment.body as SegmentBodyConcept).concept.length;
      default:
        return res;
    }
  }, 0);
};

export const softBreakPlugin = SoftBreakPlugin();

export const conceptPlugin = ConceptPlugin({ type: NodeType.CONCEPT });

export const toolbarPlugin = ToolbarPlugin({
  render: (editor, props) => {
    const {
      readOnly,
      disabled,
    } = props;

    if (readOnly) {
      return null;
    }

    return (
      <div className={cs([styles.toolbar, { [styles.disabled]: disabled }])}>
        <InsertGroupLinkButton editor={editor} />
      </div>
    );
  },
});

function ImgModal(props: any) {
  const {
    content,
    children,
  } = props;
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <Modal
        visible={visible}
        width="800px"
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
      >
        {content}
      </Modal>
      {children(setVisible)}
    </div>
  );
}

export const footerPlugin = FooterPlugin({
  render: (editor, props) => {
    const {
      readOnly,
      disabled,
      limit,
      stringLength,
      segments,
    } = props;

    if (readOnly || !limit || stringLength === undefined) {
      return null;
    }

    const imgData = (segments as TemplateSegment<SegmentBodyImage>[]).find(({ body }) => body.type === 'image');

    return (
      <div className={cs([styles.footer, { [styles.disabled]: disabled }])}>
        {
          imgData && (
            <ImgModal
              content={(
                <img
                  style={{ width: '100%' }}
                  src={`https://uploads.bot.leyantech.com/${imgData.body.imageId}`}
                  alt=""
                />
              )}
            >
              {(setVisible: any) => {
                return (
                  <div
                    className={cs(styles.preview, { [styles.previewDisabled]: disabled })}
                    onClick={() => setVisible(true)}
                  >
                    <img src={`https://uploads.bot.leyantech.com/${imgData.body.imageId}`} alt="" />
                    <div>
                      <div>订单评价</div>
                      <div>操作说明</div>
                    </div>
                    <div className={styles['icon-wrapper']}>
                      <Icon type="search" style={{ color: '#fff' }} />
                    </div>
                  </div>
                );
              }}
            </ImgModal>
          )
        }
        {
          !disabled && (
            <span
              className={styles.limit}
            >
              {`${stringLength} / ${limit}`}
            </span>
          )
        }
      </div>
    );
  },
});

export const readOnlyPlugin = ReadOnlyPlugin();

export const disabledPlugin = DisabledPlugin({
  render: (props) => {
    const {
      disabled,
      segments,
    } = props;

    return (
      <div className={cs([styles['editor-disabled'], { [styles.disabled]: disabled }])}>
        <ReadOnlyComponent segments={segments} />
      </div>
    );
  },
});

const defaultPlugins = [
  softBreakPlugin,
  conceptPlugin,
  toolbarPlugin,
  footerPlugin,
  readOnlyPlugin,
  disabledPlugin,
];

interface TemplateEditorProps {
  value: Array<TemplateSegment>,
  onChange?: (value: Array<TemplateSegment>) => void,
  onBlur?: (e?: any) => void,
  className?: string,
  limit?: number,
  validateStatus?: 'error' | string,
  disabled?: boolean,
  plugins?: any[],
  [x: string]: any,
}

const TemplateEditor = (props: TemplateEditorProps) => {
  const {
    value: segments,
    onChange = () => {},
    onBlur,
    className: classNameProp,
    readOnly = false,
    validateStatus,
    plugins = defaultPlugins,
    ...otherProps
  } = props;

  otherProps.segments = segments;

  const className = useMemo(() => {
    return cs(
      classNameProp,
      readOnly ? styles['editor-read-only'] : styles['editor-wrapper'],
      validateStatus === 'error' && styles['editor-error'],
    );
  }, [classNameProp, readOnly, validateStatus]);

  const [value, setValue] = useState<Value>(segmentsToEditorValue(segments));
  useEffect(() => {
    setValue((value) => {
      if (isEqual(segments, editorValuetoSegments(value))) {
        return value;
      }
      return segmentsToEditorValue(segments);
    });
  }, [segments]);

  const handleValueChange = useCallback(({ value }) => {
    setValue(value);
    const nextSegments = editorValuetoSegments(value);
    if (!isEqual(segments, nextSegments)) {
      onChange(nextSegments);
    }
  }, [segments, onChange]);

  return (
    <div className={className}>
      <Editor
        {...otherProps}
        className={styles.editor}
        value={value}
        onChange={handleValueChange}
        onBlur={onBlur}
        plugins={plugins}
        readOnly={readOnly}
      />
    </div>
  );
};

export default TemplateEditor;
