import Restful from 'utils/Restful';
import { marketingClient } from './clients';
import { TemplateParcel } from './paymentApi';

export interface ScriptConfigs {
  [x: string]: {
    enable: boolean,
    templates: Array<TemplateParcel>,
  },
}

export type ServiceMode = 'serviceExperienceFirst' | 'marketingFirst' | 'merchantCustomization'

interface DelayPeriod {
  index: number
  delaySeconds: number
}

interface presetConfig {
  times: number
  delaySeconds: number
  assistantStartTime: string
  assistantEndTime: string
  receivingBuyersEnable: boolean
  coolDownEnable: boolean
  coolDownValue: number
  multiDelayPeriodList?: DelayPeriod[]
}

export interface OrderReminder {
  times: number,
  enable: boolean,
  delaySeconds: number,
  marketingMode: ServiceMode,
  serviceExperienceFirst: presetConfig,
  marketingFirst: presetConfig,
  merchantCustomization: presetConfig,
  scriptConfigs: ScriptConfigs | null,
}

export interface StatsDetailQuery {
  start: string,
  end: string,
  strategy: string,
  times: string,
  orderStatus: string,
  replyStatus: string,
  keyword: string,
  orderBy: string,
  orderField: string,
  pageNum: number,
  pageSize: number,
}

export interface Pages {
  pageNum: number,
  pageSize: number,
  totalPages: number,
  totalRows: number,
}

export interface OrderInfo {
  orderStatus: string,
  orderData: {
    orderId: string,
    orderAmount: string,
  }[],
}

export interface StatsDetail {
  dispatchedTimeMs: string,
  buyNick: string,
  strategy: string,
  times: number,
  orderInfo: OrderInfo,
  replyInfo: {
    replyStatus: string,
    replyTime: number,
  },
}

export interface PageData<T> {
  pages: Pages,
  data: Array<T>,
}

export interface StatsQuery {
  start: string,
  end: string,
}

export interface StatsTotal {
  totalOrderAmount: number,
  totalOrderNum: number,
  totalReplyRate: number,
  totalReplyNum: number,
  totalPayRate: number,
}

export interface StatsItem {
  orderAmount: string,
  orderNum: number,
  replyNum: number,
  replyRate: string,
  payRate: number,
  date: string,
}

export interface StatsData extends StatsTotal {
  data: Array<StatsItem>,
}

class OrderReminderApi extends Restful {
  fetch() :Promise<OrderReminder> {
    return this.get();
  }

  update(body: OrderReminder) :Promise<OrderReminder> {
    return this.put(body);
  }

  stats(params: StatsQuery): Promise<{stats: StatsData}> {
    return this
      .one('stats')
      .get({
        params,
      });
  }

  statsDetail(params: StatsDetailQuery) :Promise<PageData<StatsDetail>> {
    return this
      .one('stats')
      .one('detail')
      .list({
        params,
      });
  }
}

export default new OrderReminderApi({
  client: marketingClient,
  entry: '/order_reminder',
});
