const STORAGE_TOKEN_NAME = process.env.REACT_APP_STORAGE_TOKEN_NAME as string;
const AUTH_HEADER_NAME = process.env.REACT_APP_AUTH_HEADER_NAME as string;
const TURNSTILE_AUTH_HEADER_NAME = process.env.REACT_APP_TURNSTILE_AUTH_HEADER_NAME as string;
const MARKTEING_ENDPOINT = process.env.REACT_APP_MARKTEING_ENDPOINT as string;
const FORBIDDEN_WORD_ENDPOINT = process.env.REACT_APP_FORBIDDEN_WORD_ENDPOINT as string;
const TURNSTILE_ENDPOINT = process.env.REACT_APP_TURNSTILE_ENDPOINT as string;

export {
  STORAGE_TOKEN_NAME,
  AUTH_HEADER_NAME,
  TURNSTILE_AUTH_HEADER_NAME,
  MARKTEING_ENDPOINT,
  FORBIDDEN_WORD_ENDPOINT,
  TURNSTILE_ENDPOINT,
};
