import React from 'react';
import { Card, Skeleton } from 'antd';

const Loading = () => (
  <Card>
    <Skeleton active />
  </Card>
);

export default Loading;
