import Restful from 'utils/Restful';
import { marketingClient } from './clients';

export type SceneName = 'payment_reminder' | 'group_reminder' | 'order_reminder' | 'praise_reminder'

export interface Config<T> {
  enable: boolean
  delaySeconds: number | undefined
  times: number
  scriptConfigs: T
}

export interface StatsParams {
  start: string
  end: string
}

export interface Stats<T> {
  data: Array<T>
}

export interface Pages {
  pageNum: number
  pageSize: number
  totalPages: number
  totalRows: number
}

export interface PageData<T> {
  pages: Pages
  data: Array<T>
}

class MarketingApi extends Restful {
  getConfig<T>(sceneName: SceneName): Promise<Config<T>> {
    return this
      .custom(sceneName)
      .get();
  }

  checkEnable<T>(sceneName: SceneName): Promise<Config<T>> {
    return this
      .custom(`${sceneName}/enable`)
      .get();
  }

  updateConfig<T>(sceneName: SceneName, config: Config<T>): Promise<Config<T>> {
    return this
      .custom(sceneName)
      .put(config);
  }

  getStats<T>(sceneName: SceneName, params: StatsParams): Promise<Stats<T>> {
    return this
      .custom(sceneName)
      .one('stats')
      .get({ params });
  }

  getStatsDetail<T, K>(
    sceneName: SceneName,
    body: T,
  ): Promise<PageData<K>> {
    return this
      .custom(sceneName)
      .one('stats')
      .one('detail')
      .post(body);
  }
}

export default new MarketingApi({
  client: marketingClient,
});
