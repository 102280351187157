import { ReactNode } from 'react';
import { Editor } from 'slate';

interface DisabledPluginProps {
  render: (props: any) => ReactNode,
}

const DisabledPlugin = (props: DisabledPluginProps) => {
  const {
    render,
  } = props;

  const renderEditor = (props: any, editor: Editor, next: () => ReactNode) => {
    const {
      disabled,
    } = props;

    return disabled ? render(props) : next();
  };

  return {
    renderEditor,
  };
};

export default DisabledPlugin;
