import { TemplateSegment, SegmentBodyText } from 'apis/paymentApi';

const segmentsToTexts = (segments: TemplateSegment[]): string[] => {
  const _segments: TemplateSegment<SegmentBodyText>[] = segments.reduce(
    (res: TemplateSegment<SegmentBodyText>[], segment): TemplateSegment<SegmentBodyText>[] => {
      const {
        body: {
          type,
        },
      } = segment;
      const last = res[res.length - 1] as TemplateSegment<SegmentBodyText>;
      if (type === 'text' && last && last.body.type === 'text') {
        const {
          text,
        } = segment.body as SegmentBodyText;
        last.body.text += text;
        return res;
      }
      return [...res, segment as TemplateSegment<SegmentBodyText>];
    },
    [],
  );

  return _segments.reduce((res, segment) => {
    const {
      body: {
        type,
        text,
      },
    } = segment;
    if (type === 'text') {
      (res as string[]).push(text);
    }
    return res;
  }, []);
};

export default segmentsToTexts;
