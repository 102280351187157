import React, { useCallback } from 'react';
import { Icon } from 'antd';
import { SwitchProps } from 'antd/es/switch';
import cs from 'utils/classNames';
import styles from './styles.module.scss';

const SimpleSwitch = (props: SwitchProps) => {
  const {
    checked,
    onChange,
    className,
    checkedChildren,
    unCheckedChildren,
  } = props;

  const handleClick = useCallback((e) => {
    if (onChange) {
      onChange(!checked, e);
    }
  }, [onChange, checked]);

  return (
    <span
      className={cs([
        className,
        styles['simple-switch'],
        checked ? styles.grey : styles.blue,
      ])}
      onClick={handleClick}
    >
      {
        checked ? (
          checkedChildren || (
            <>
              <Icon type="stop" />
              <span>禁用该策略</span>
            </>
          )
        ) : (
          unCheckedChildren || (
            <>
              <Icon type="unlock" />
              <span>启用该策略</span>
            </>
          )
        )
      }
    </span>
  );
};

export default SimpleSwitch;
