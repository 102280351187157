import React from 'react';
import { Editor } from 'slate';
import cs from 'utils/classNames';
import styles from './styles.module.scss';

interface ConceptProps {
  concept: string,
  className?: string,
  isFocused?: boolean,
  [x: string]: any,
}

export const Concept = (props: ConceptProps) => {
  const {
    concept,
    className: classNameProp,
    isFocused,
    ...otherProps
  } = props;

  const className = cs(
    styles.concept,
    classNameProp,
    isFocused && styles.focused,
  );

  return (
    <span
      {...otherProps}
      className={className}
    >
      @
      {concept}
    </span>
  );
};

interface ConceptPluginProps {
  type: string,
  [x: string]: any,
}

const ConceptPlugin = (options: ConceptPluginProps): any => {
  const {
    type,
    className,
    ...otherProps
  } = options;

  const schema = {
    inlines: {
      [type]: {
        isVoid: true,
      },
    },
  };

  const insertConcept = (editor: Editor, concept: string) => {
    return editor
      .insertInline({
        type,
        data: {
          concept,
        },
      })
      .moveToStartOfNextText()
      .focus();
  };

  const renderNode = (props: any, editor: Editor, next: () => any): any => {
    // eslint-disable-next-line
    if (props.node.type !== type) {
      return next();
    }

    const {
      key,
      attributes,
      node: {
        data,
      },
      isFocused,
    } = props;

    const concept = data.get(type);

    return (
      <Concept
        key={key}
        className={className}
        concept={concept}
        isFocused={isFocused}
        {...attributes}
        {...otherProps}
      />
    );
  };

  return {
    schema,
    commands: {
      insertConcept,
    },
    renderNode,
  };
};

export default ConceptPlugin;
