import Restful from 'utils/Restful';
import { turnstileClient } from './clients';

class GroupReminderApi extends Restful {
  shop(): Promise<any> {
    return this
      .one('shop')
      .get();
  }
}

export default new GroupReminderApi({
  client: turnstileClient,
  entry: '/',
});
