interface IHeaderAuth {
  _header: string;
  _prefix: string;
  authorize<T>(client: T, token: string): T;
}
class HeaderAuth implements IHeaderAuth {
  constructor(header: string, prefix?: string) {
    this._header = header;
    this._prefix = prefix ? `${prefix} ` : '';
  }

  public _header = '';

  public _prefix = '';

  authorize(client: any, token: string) {
    const { headers } = client.config() || {};

    return client.patchConfig({
      headers: {
        ...headers,
        [this._header]: `${this._prefix}${token}`,
      },
    });
  }
}

export default HeaderAuth;
