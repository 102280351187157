import React, { ReactNode, useCallback } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

const {
  Option,
} = Select;

interface IOption {
  value: string | number,
  label: string | ReactNode,
}

interface MultipleSelectProps extends Omit<SelectProps, 'onChange'> {
  title: string,
  options: Array<IOption>,
  onChange: (value: Array<any>) => void,
  width?: number | string,
}

const MultipleSelect = (props: MultipleSelectProps) => {
  const {
    title,
    options,
    width = 200,
    value = [],
    onChange,
    ...otherProps
  } = props;

  const handleSelect = useCallback((select) => {
    if (select === 'all' || (value as string[]).length === options.length - 2) {
      onChange(options.map(({ value }) => value));
    } else {
      onChange([...(value as string[]), select]);
    }
  }, [value, options, onChange]);

  const handleDeselect = useCallback((select) => {
    if (select === 'all') {
      onChange([]);
    } else {
      onChange((value as string[]).filter((item) => item !== 'all' && item !== select));
    }
  }, [value, onChange]);

  const getTagPlaceholder = useCallback((current) => {
    switch (current.length) {
      case 1: {
        const currentOption = options.find(({ value }) => value === current[0]);
        return currentOption ? currentOption.label : '';
      }
      case options.length:
        return `已选择全部${title}`;
      default:
        return `已选择${current.length}种${title}`;
    }
  }, [options, title]);

  return (
    <Select
      mode="multiple"
      placeholder={title}
      value={value}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      maxTagCount={0}
      maxTagPlaceholder={getTagPlaceholder}
      style={{
        width,
      }}
      {...otherProps}
    >
      {
        options.map(({ value, label }) => (
          <Option
            key={value}
            value={value}
          >
            {label}
          </Option>
        ))
      }
    </Select>
  );
};

export default MultipleSelect;
