const SPACE_REG = /\s+/;

interface ClassNameEntries {
  [prop: string]: boolean,
}

function createClassNameEntries(
  params: any,
  classSet: ClassNameEntries = {},
  strict: boolean = false,
): ClassNameEntries {
  if (params === null || params === undefined) {
    return classSet;
  }

  if (Array.isArray(params)) {
    return params.reduce((result, x) => createClassNameEntries(x, result, strict), classSet);
  }

  switch (typeof params) {
    case 'object': {
      return Object.entries(params).reduce((result, [key, value]) => {
        if (value) {
          return createClassNameEntries(key, result, strict);
        }

        return result;
      }, classSet);
    }
    case 'number': {
      return {
        ...classSet,
        [params]: true,
      };
    }
    case 'string': {
      const str = params.trim();

      if (str === '') {
        return classSet;
      }

      if (strict && SPACE_REG.test(str)) {
        return createClassNameEntries(str.split(SPACE_REG), classSet, strict);
      }

      return {
        ...classSet,
        [str]: true,
      };
    }
    default: {
      return classSet;
    }
  }
}

function createClassName(params: any, strict?: boolean): string | undefined {
  const keys = Object.keys(createClassNameEntries(params, {}, strict));

  return keys.length ? keys.join(' ') : undefined;
}

export function strictClassName(...params: any[]) {
  return createClassName(params, true);
}

function className(...params: any[]) {
  return createClassName(params);
}

export default className;
