import React, {
  Suspense,
  useMemo,
  useEffect,
  createContext,
  useState,
} from 'react';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { Layout } from 'antd';
import marketingApi from 'apis/marketingApi';
import Loading from 'components/Loading';
import Forbidden from 'views/Forbidden';
import NotFound from 'views/NotFound';
import { useSlotRef } from 'components/Slot';
import useAuth, { Collection } from 'hooks/useAuth';
import Modal from 'components/Modal';
import font from 'views/Group/Notice/assets/font-2.svg';
import useLocalStorage from 'hooks/useLocalStorage';
import Notice from '../Group/Notice';
import Nav from './Nav';
import _routes, { grayRoutes } from './routes';
import styles from './styles.module.scss';


interface MainContextValue {
  scrollToTop: () => void
  collections: Array<Collection>
}

const {
  Sider,
  Content,
} = Layout;

export const MainContext = createContext<MainContextValue>({} as MainContextValue);

const Main = () => {
  const {
    authorizing,
    authorized,
    collections,
  } = useAuth();

  const slotRef = useSlotRef<HTMLDivElement>();

  const history = useHistory();

  const [routes, setRoutes] = useState(_routes);

  const showRoutes = useMemo(() => {
    if (authorizing || !collections) {
      return [];
    }

    return routes;
  }, [authorizing, collections, routes]);

  const [showModal, setShowModal] = useLocalStorage('group-discarded', true);

  useEffect(() => {
    const {
      location: {
        pathname,
      },
      replace,
    } = history;

    if (
      authorized
      && (pathname === '/401' || pathname === '/404')
      && showRoutes.length
    ) {
      replace(showRoutes[0].path);
    }
  }, [authorized, showRoutes, history]);

  useEffect(() => {
    if (authorized) {
      marketingApi.checkEnable('praise_reminder')
        .then((enable) => {
          if (enable) {
            setRoutes([
              ..._routes,
              ...grayRoutes,
            ]);
          }
        }).catch((err) => {
          console.log(err);
        });
    }
  }, [authorized]);

  const contextValue = useMemo<MainContextValue>(() => {
    function scrollToTop(top = 0) {
      if (slotRef.current) {
        (slotRef.current.parentNode as HTMLElement).scroll({
          top,
          behavior: 'smooth',
        });
      }
    }

    return {
      scrollToTop,
      collections,
    };
  }, [slotRef, collections]);

  return (
    <MainContext.Provider value={contextValue}>
      <Modal
        show={showModal}
      >
        <Notice
          layout="vertical"
          style={{
            width: '500px',
          }}
          title={(
            <img
              src={font}
              alt="title"
              style={{
                margin: '16px auto',
                display: 'block',
              }}
            />
          )}
          body={(
            <span>
              商家您好！为配合拼多多官方规则调整，智能促成团能力已于9月23日下线，全部能力会于12月31日时下线，如需要，请提早将“收益情况”截图保存或下载明细。
            </span>
          )}
        >
          <button
            onClick={() => setShowModal(false)}
            type="button"
            className={styles.button}
          >
            我知道了
          </button>
        </Notice>
      </Modal>
      <Layout className={styles['main-layout']}>
        <Sider>
          <Nav routes={showRoutes} />
        </Sider>
        <Content>
          <div
            className={styles['content-top']}
            ref={slotRef}
          />
          <Suspense fallback={<Loading />}>
            {
              authorizing ? null : (
                <Switch>
                  <Route exact path="/401" component={Forbidden} />
                  {
                    !authorized && <Redirect to="/401" />
                  }
                  {
                    showRoutes.map((route) => {
                      return (
                        <Route key={route.path} {...route} />
                      );
                    })
                  }
                  <Route path="/404" component={NotFound} />
                  <Redirect to="404" />
                </Switch>
              )
            }
          </Suspense>
        </Content>
      </Layout>
    </MainContext.Provider>
  );
};

export default Main;
