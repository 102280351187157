import React, { useCallback } from 'react';
import { Button, Icon } from 'antd';
import { ButtonProps } from 'antd/es/button';
import cs from 'utils/classNames';
import styles from './styles.module.scss';

interface InsertGroupLinkButtonProps extends ButtonProps {
  editor: any;
}

const InsertGroupLinkButton = (props: InsertGroupLinkButtonProps) => {
  const {
    editor,
    className: classNameProp,
    ...otherProps
  } = props;

  const className = cs(
    classNameProp,
    styles['link-button'],
  );

  const handleButtonClick = useCallback(() => {
    if (typeof editor.insertConcept === 'function') {
      editor.insertConcept('拼团链接');
    }
  }, [editor]);

  return (
    <Button
      className={className}
      type="link"
      onClick={handleButtonClick}
      {...otherProps}
    >
      <Icon
        className={styles['link-icon']}
        type="link"
      />
      插入参数
    </Button>
  );
};

export default InsertGroupLinkButton;
