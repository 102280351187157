import Restful from 'utils/Restful';
import { forbiddenWordClient } from './clients';

type JudgeResult = Record<number, Array<string>>

class JudgeApi extends Restful {
  judge(body: string[]): Promise<JudgeResult> {
    return this.post(body);
  }
}

export default new JudgeApi({
  client: forbiddenWordClient,
  entry: '/judge',
});
