import React, { ReactNode } from 'react';
import { Editor } from 'slate';

interface ToolbarPluginProps {
  render: (...props: any[]) => ReactNode,
}

const ToolbarPlugin = (props: ToolbarPluginProps) => {
  const {
    render,
  } = props;

  const renderEditor = (props: any, editor: Editor, next: () => ReactNode) => {
    return (
      <>
        {render(editor, props)}
        {next()}
      </>
    );
  };

  return {
    renderEditor,
  };
};

export default ToolbarPlugin;
