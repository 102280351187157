import React, { ReactNode } from 'react';
import { Spin, Icon } from 'antd';
import { SpinProps } from 'antd/es/spin';
import { ReactComponent as LockSvg } from './assets/lock.svg';
import styles from './styles.module.scss';

interface LockProps extends SpinProps {
  children?: ReactNode,
}

const Lock = (props: LockProps) => {
  return (
    <Spin
      indicator={(
        <div className={styles['lock-wrapper']}>
          <Icon component={LockSvg} />
          <p>设置完场景有数据后</p>
          <p>可查看哦~</p>
        </div>
      )}
      {...props}
    />
  );
};

export default Lock;
