import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  Modal,
  DatePicker,
  Input,
  Button,
  Table,
  message,
  Icon,
  Tooltip,
} from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { ColumnProps, SortOrder } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import { useBoolean } from 'react-use';
import MultipleSelect from 'components/MultipleSelect';
import orderApi, { Pages, StatsDetail, OrderInfo } from 'apis/orderApi';
import { ScriptLabel } from '../Setting';
import styles from './styles.module.scss';

const {
  RangePicker,
} = DatePicker;

const ALL = 'all';

const strategyOptions = [
  {
    value: ALL,
    label: '全部',
  },
  ...Object.entries(ScriptLabel).map(([value, label]) => ({
    value,
    label,
  })),
];

const timesOptions = [
  {
    value: ALL,
    label: '全部',
  },
  {
    value: '1',
    label: '第 1 次',
  },
  {
    value: '2',
    label: '第 2 次',
  },
  {
    value: '3',
    label: '第 3 次',
  },
];

const OrderStatusMap: Record<string, string> = {
  [ALL]: '全部',
  0: '等待中',
  1: '已下单',
  2: '未下单',
};

const orderStatusOptions = Object.entries(OrderStatusMap).map(([value, label]) => ({
  value,
  label,
}));

const ReplyStatusMap: Record<string | number, string> = {
  [ALL]: '全部',
  0: '等待中',
  1: '已回复',
  2: '未回复',
};

const replyStatusOptions = Object.entries(ReplyStatusMap).map(([value, label]) => ({
  value,
  label,
}));

const classNameMap: Record<string | number, string> = {
  0: styles.waiting,
  1: styles.success,
  2: styles.failed,
};

const getArrayQuery = (arr: string[]): string => {
  if (!arr.length || arr.includes(ALL)) {
    return ALL;
  }
  return arr.join(',');
};

const OrderInfoComponent = (props: OrderInfo) => {
  const {
    orderStatus,
    orderData,
  } = props;

  const [fold, toggleFold] = useBoolean(true);

  return (
    <div>
      <div className={classNameMap[orderStatus]}>
        {OrderStatusMap[orderStatus]}
      </div>
      {
        (orderStatus === '1' && orderData)
        && (fold ? orderData.slice(0, 5) : orderData).map(({ orderId, orderAmount }) => {
          return (
            <div key={orderId}>
              {`订单号：${orderId} （${orderAmount}元）`}
            </div>
          );
        })
      }
      {
        orderStatus === '1' && orderData.length > 5 && (
          <Button
            className={styles['fold-button']}
            type="link"
            onClick={toggleFold}
          >
            {
              fold ? (
                <>
                  <span>{`展开剩余订单（${orderData.length - 5}）`}</span>
                  <Icon type="down" />
                </>
              ) : (
                <>
                  <span>收起</span>
                  <Icon type="up" />
                </>
              )
            }
          </Button>
        )
      }
    </div>
  );
};

const TableModal = (props: ModalProps) => {
  const {
    visible,
    ...otherProps
  } = props;

  const [range, setRange] = useState<[Moment, Moment]>([moment(), moment()]);
  const [strategy, setStrategy] = useState<string[]>([]);
  const [times, setTimes] = useState<string[]>([]);
  const [orderStatus, setOrderStatus] = useState<string[]>([]);
  const [replyStatus, setReplyStatus] = useState<string[]>([]);
  const [keyword, setKeyword] = useState<string>('');
  const [orderBy, setOrderBy] = useState<SortOrder>('descend');
  const [currentPages, setCurrentPages] = useState<Pages>();
  const [data, setData] = useState<StatsDetail[]>();
  const [searching, setSearching] = useState<boolean>(false);

  const columns: ColumnProps<StatsDetail>[] = useMemo(() => ([
    {
      title: '下发时间',
      dataIndex: 'dispatchedTimeMs',
      key: 'dispatchedTimeMs',
      sorter: true,
      sortOrder: orderBy,
      render: (time) => moment(time).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '买家昵称',
      dataIndex: 'buyNick',
      key: 'buyNick',
    },
    {
      title: '营销策略',
      dataIndex: 'strategy',
      key: 'strategy',
      render: (strategy) => ScriptLabel[strategy],
    },
    {
      title: '挽回次数',
      dataIndex: 'times',
      key: 'times',
      render: (times) => `第 ${times} 次`,
    },
    {
      title: () => (
        <>
          <span className={styles['title-text']}>下单情况</span>
          <Tooltip title="营销发送后，需等待一段时间，等待买家下单">
            <Icon
              className={styles['title-icon']}
              type="question-circle"
              theme="filled"
            />
          </Tooltip>
        </>
      ),
      dataIndex: 'orderInfo',
      key: 'orderInfo',
      render: (info: OrderInfo) => <OrderInfoComponent {...info} />,
    },
    {
      title: () => (
        <>
          <span className={styles['title-text']}>回复情况</span>
          <Tooltip title="营销发送后，需等待一段时间，等待买家回复">
            <Icon
              className={styles['title-icon']}
              type="question-circle"
              theme="filled"
            />
          </Tooltip>
        </>
      ),
      dataIndex: 'replyInfo',
      key: 'replyInfo',
      render: (info) => {
        const {
          replyStatus,
          replyTime,
        } = info;

        return (
          <div>
            <div className={classNameMap[replyStatus]}>
              {ReplyStatusMap[replyStatus]}
            </div>
            {
              replyStatus === '1' && replyTime && (
                <div>{moment(replyTime).format('YYYY-MM-DD HH:mm:ss')}</div>
              )
            }
          </div>
        );
      },
    },
  ]), [orderBy]);

  const onSearch = useCallback(({ params = {} } = {}) => {
    setSearching(true);

    const {
      orderBy: orderByParam = orderBy,
    } = params;

    orderApi
      .statsDetail({
        start: range[0].format('YYYY-MM-DD'),
        end: range[1].format('YYYY-MM-DD'),
        strategy: getArrayQuery(strategy),
        times: getArrayQuery(times),
        orderStatus: getArrayQuery(orderStatus),
        replyStatus: getArrayQuery(replyStatus),
        keyword,
        pageNum: 1,
        pageSize: currentPages ? currentPages.pageSize : 10,
        orderField: 'dispatchTime',
        ...params,
        orderBy: orderByParam === 'descend' ? 'desc' : 'asc',
      })
      .then((res) => {
        const {
          pages,
          pages: {
            pageNum,
            pageSize,
          },
          data,
        } = res;

        setData(data.map((item, index) => ({
          ...item,
          id: pageNum * pageSize + index,
        })));
        setCurrentPages(pages);
        setOrderBy(orderByParam);
      })
      .catch(() => {
        message.error('获取详细数据失败');
      })
      .finally(() => {
        setSearching(false);
      });
  }, [range, strategy, times, orderStatus, replyStatus, keyword, currentPages, orderBy]);

  useEffect(() => {
    if (visible && !data) {
      onSearch();
    }
  }, [visible, data, onSearch]);

  const handleResetClick = useCallback(() => {
    setRange([moment(), moment()]);
    setStrategy([]);
    setTimes([]);
    setOrderStatus([]);
    setReplyStatus([]);
    setKeyword('');
  }, []);

  const handleTableChange = useCallback((pagination, filter, sorter) => {
    const {
      current: pageNum,
      pageSize,
    } = pagination;
    const {
      order = (orderBy === 'ascend' ? 'descend' : 'ascend'),
    } = sorter;

    onSearch({
      params: {
        orderBy: order,
        pageNum,
        pageSize,
      },
    });
  }, [orderBy, onSearch]);

  return (
    <Modal
      title="发送明细"
      width={1400}
      visible={visible}
      footer={null}
      {...otherProps}
    >
      <div className={styles.controller}>
        <RangePicker
          className={styles.range}
          value={range}
          onChange={(range) => setRange(range as [Moment, Moment])}
          allowClear={false}
          disabledDate={(current) => {
            if (
              (current as Moment).isAfter(moment())
              || (current as Moment).isBefore(moment().subtract(7, 'days'))
            ) {
              return true;
            }
            return false;
          }}
        />
        <MultipleSelect
          width={185}
          title="营销策略"
          options={strategyOptions}
          value={strategy}
          onChange={setStrategy}
        />
        <MultipleSelect
          width={185}
          title="挽回次数"
          options={timesOptions}
          value={times}
          onChange={setTimes}
        />
        <MultipleSelect
          width={185}
          title="下单情况"
          options={orderStatusOptions}
          value={orderStatus}
          onChange={setOrderStatus}
        />
        <MultipleSelect
          width={185}
          title="回复情况"
          options={replyStatusOptions}
          value={replyStatus}
          onChange={setReplyStatus}
        />
        <Input
          className={styles.input}
          placeholder="请输入订单编号"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <Button
          type="primary"
          onClick={onSearch}
        >
          查询
        </Button>
        <Button onClick={handleResetClick}>
          重置
        </Button>
      </div>
      <Table
        loading={searching}
        columns={columns}
        dataSource={data}
        rowKey="id"
        onChange={handleTableChange}
        pagination={currentPages ? {
          total: currentPages.totalRows,
          current: currentPages.pageNum,
          pageSize: currentPages.pageSize,
          pageSizeOptions: ['5', '10', '15', '20'],
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total: number) => `已为您查询到 ${total} 条记录`,
        } : false}
      />
    </Modal>
  );
};

export default TableModal;
