import React from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale-provider/zh_CN';
import Main from 'views/Main';
import Slot from 'components/Slot';
import history from 'utils/history';

const App = () => {
  return (
    <ConfigProvider locale={locale}>
      <Slot>
        <Router history={history}>
          <Switch>
            <Route path="/" component={Main} />
          </Switch>
        </Router>
      </Slot>
    </ConfigProvider>
  );
};

export default App;
