import withAuth from 'utils/withAuth';
import HeaderAuth from 'utils/HeaderAuth';
import AxiosClient from 'utils/AxiosClient';
import {
  AUTH_HEADER_NAME,
  TURNSTILE_AUTH_HEADER_NAME,
  MARKTEING_ENDPOINT,
  FORBIDDEN_WORD_ENDPOINT,
  TURNSTILE_ENDPOINT,
} from 'config/react-app';
import responseIntercepter from './responseIntercepter';

const headerAuth = new HeaderAuth(AUTH_HEADER_NAME, 'Bearer');
const AuthClient = withAuth(headerAuth)(AxiosClient);

const turnstileHeaderAuth = new HeaderAuth(TURNSTILE_AUTH_HEADER_NAME);
const TurnstileAuthClient = withAuth(turnstileHeaderAuth)(AxiosClient);

export const marketingClient = new AuthClient(
  {
    baseURL: MARKTEING_ENDPOINT,
  },
  {
    response: responseIntercepter,
  },
);

export const forbiddenWordClient = new AuthClient(
  {
    baseURL: FORBIDDEN_WORD_ENDPOINT,
  },
  {
    response: responseIntercepter,
  },
);

export const turnstileClient = new TurnstileAuthClient(
  {
    baseURL: TURNSTILE_ENDPOINT,
  },
  {
    response: responseIntercepter,
  },
);

export function authorize(token: string) {
  marketingClient.authorize(token);
  forbiddenWordClient.authorize(token);
  turnstileClient.authorize(token);
}

export default {
  marketingClient,
};
