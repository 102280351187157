
import React, { ReactNode } from 'react';
import cs from 'utils/classNames';
import styles from './styles.module.scss';

interface TabCardsProps {
  children: ReactNode[],
  activeCard: number,
  cards: any[],
  onChange?: (val: number) => void
}


export default function TabCards(props: TabCardsProps) {
  const {
    cards,
    children,
    activeCard,
    onChange = () => {},
  } = props;

  function changeCard(idx: number) {
    onChange(idx);
  }

  return (
    <div>
      <div className={styles.tabCards}>
        {
          cards.map((item, idx) => {
            return (
              <div
                onClick={() => changeCard(idx)}
                key={item.title}
                className={cs([
                  styles.tabCard,
                  {
                    [styles.selected]: activeCard === idx,
                    [styles.recommend]: idx < cards.length - 1,
                  }])}
              >
                <h3>{item.title}</h3>
                <span>{item.subTitle}</span>
              </div>
            );
          })
        }
      </div>

      <div
        className={cs([
          styles['tabs-wrapper'],
          styles.wrapperFix,
        ])}
      >
        <div
          className={styles['tabs-wrapper-cursor']}
          style={{ left: `${((activeCard) / cards.length) * 100}%` }}
        />
        {
          children[activeCard]
        }
      </div>
    </div>
  );
}
