import React, { HTMLAttributes, ReactNode, useState } from 'react';
import { Icon } from 'antd';
import cs from 'utils/classNames';
import { ReactComponent as BotSvg } from './assets/iconBot.svg';
import styles from './styles.module.scss';

interface TipsProps extends HTMLAttributes<HTMLDivElement> {
  content: string | ReactNode,
  color?: 'blue' | 'orange'
}

const Tips = (props: TipsProps) => {
  const {
    content,
    className,
    color = 'orange',
    ...otherProps
  } = props;

  const [visible, setVisible] = useState(true);

  switch (color) {
    case 'blue':
      return (
        <div className={cs(styles.tipBox, { [styles.hidden]: !visible })} {...otherProps}>
          <span className={styles.tips}>言准建议：</span>
          {content}
          <Icon type="close" style={{ float: 'right', lineHeight: '28px' }} onClick={() => setVisible(false)} />
        </div>
      );

    default:
      return (
        <div className={cs([styles.tips, className])}>
          <span className={styles['tips-title']}>
            <Icon component={BotSvg} style={{ color: '#f00' }} />
            <span>言准建议</span>
          </span>
          <span className={styles['tips-content']}>
            {content}
          </span>
        </div>
      );
  }
};

export default Tips;
