import React, { HTMLAttributes, ReactNode } from 'react';
import { Icon, Tooltip } from 'antd';
import cs from 'utils/classNames';
import styles from './styles.module.scss';

interface CheckTagProps extends Omit<HTMLAttributes<HTMLSpanElement>, 'title'> {
  disabled?: boolean
  title?: string | ReactNode
  description?: string
}

const CheckTag = (props: CheckTagProps) => {
  const {
    children,
    className,
    disabled = false,
    title,
    description,
    ...otherProps
  } = props;

  return (
    <span
      className={cs([
        className,
        styles.tag,
        { [styles['tag-disable']]: disabled },
      ])}
      {...otherProps}
    >
      {
        children || (
          <>
            <span>{title}</span>
            <Tooltip title={description}>
              <Icon
                className={styles['tag-icon']}
                type="question-circle"
              />
            </Tooltip>
          </>
        )
      }
      <Icon className={styles['tag-check']} type="check" />
    </span>
  );
};

export default CheckTag;
